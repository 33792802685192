












































import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";
import { computed, defineComponent, reactive, ref } from "@vue/composition-api";

export default defineComponent({
  components: {
    NavigationDrawer: () => import("@/views/layout/drawer.vue")
  },
  setup(props, { root }) {
    const item = reactive({});
    const lang = ref("en");

    const items = [
      { title: "English", lang: "en", display: "En" },
      { title: "繁體中文", lang: "zh", display: "繁" }
    ];

    const appbarItems = computed(() => UserModule.topBarMenu);
    const appbarTitle = computed(() => root.$t("appTitle") as string);
    const hasError = computed(() => AppModule.hasError);
    const errorMessage = computed(() => AppModule.errorMessage);

    function click(item) {
      lang.value = item.display;
      root.$i18n.locale = item.lang;
    }

    function toggleSideBar(): void {
      AppModule.ToggleSideBar(true);
    }

    return {
      toggleSideBar,
      appbarItems,
      hasError,
      items,
      item,
      errorMessage,
      appbarTitle,
      lang,
      click
    };
  }
});
